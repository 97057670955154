.timeline {
  .timeline {
    &-content {
      position: relative;
    }

    &-line {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;

      width: 1px;

      transform: translateX(-50%);

      background-color: #e0e0e0;
    }

    &-label {
      position: relative;

      text-align: center;

      &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;

        display: block;

        width: 1px;
        height: 100%;

        content: '';
        transition: height 5s ease;
        transform: translateX(-50%);

        background-color: $primary;
      }

      // &.aos-init {
      //   &::before {
      //     height: 0;
      //   }
      // }

      &.timeline-animated {
        &::before {
          height: 100%;
        }
      }

      span {
        font-size: rem(20px);
        font-weight: $font-weight-regular;

        display: inline-block;

        margin: 40px auto;
        padding: 4px 11px;

        color: $white;
        background-color: $secondary;
      }

      &:first-child {
        span {
          margin-top: 0;
        }
      }
    }

    &-card {
      position: relative;

      padding-bottom: rem(18px);

      &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;

        display: block;

        width: 1px;
        height: 100%;

        content: '';
        transition: height 5s ease;
        transform: translateX(-50%);

        background-color: $primary;
      }

      // &.aos-init {
      //   &::before {
      //     height: 0;
      //   }
      // }

      &.timeline-animated {
        &::before {
          height: 100%;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }

      .card {
        width: 100%;
        max-width: 425px;
      }

      + .timeline-label {
        margin-top: rem(-18px); // disable pb for the last timeline-card
      }                         // before a new section
    }

    // &-date {
    //   font-size: rem(12px);
    //   font-weight: $font-weight-light;

    //   color: $body-color;
    // }

    &-image {
      + .card {
        margin-top: -60px !important;
      }
    }
  }
}
