.news {
  &-list {
    &.slider {
      .card {
        border: none;
        background-color: $brand-light;


        .preline {
          font-size: 14px;
          font-weight: 300;

          color: #b6272d;
        }
      }

      .slick {
        &-track {
          display: flex;

          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
