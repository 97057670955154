.downloads {
  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .card {
    height: 100%;

    border: none;
    background-color: $brand-light;

    justify-content: center;

    .preline {
      &::after {
        display: none;
      }
    }

    &-body {
      flex: 0;
      // padding-top: $card-spacer-y/2.5;
      // padding-right: $card-spacer-x/1.5;
      // padding-bottom: $card-spacer-y/2.5;
      // padding-left: $card-spacer-x/1.5;

      padding: 16px 22px;
    }

    svg {
      color: $secondary;
    }

    .preline {
      font-size: rem(14px);
      font-weight: 400;

      color: rgba(80,80,80,0.5);
    }

    .headline {
      font-size: rem(18px);
      font-weight: 400;

      margin-bottom: 0;

      color: $body-color;
    }
  }
}
