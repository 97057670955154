
$brand-primary: #b6272d;
$brand-secondary: #9d9d9c;
$brand-tertiary: #565656;

$brand-white: #fff;
$brand-black: #000;

$brand-light: #f4f4f4;
$brand-dark: #333;

$brand-success: #28a745;
$brand-danger: #dc3545;
$brand-warning: #ffc107;
$brand-info: #17a2b8;
