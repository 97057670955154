#pre-footer {
  .google-maps,
  .google-maps-and-contacts {
    margin-right: $container-padding-x;
    margin-left: $container-padding-x;
  }
}

#footer {
  // border-top: 1px solid #dbdad9;
}

.footer {
  z-index: 1;

  .additional-image {
    img {
      display: block;

      margin: 0 auto;
    }
  }

  .block-items {
    &-text {
      p {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-link {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footerlinks {
    .link {
      font-weight: $font-weight-semi-bold;

      color: transparentize($color: $body-color, $amount: 0.5);

      &:hover,
      &:focus,
      &:active {
        color: $body-color;
      }

      &::after {
        width: 2px;
      }
    }
  }

  .copyright {
    .teaser {
      font-size: $font-size-sm !important;
      font-weight: $font-weight-regular;

      opacity: 0.5;
    }
  }
}
