.news {
  &-list {
    &.wall {
      .card {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
