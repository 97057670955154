.stage {
  &.video {
    display: flex;

    @include media-breakpoint-down(xl) {
      h1.headline {
        font-size: 3rem;
      }
    }

    &::after {
      position: absolute;

      display: block;

      content: '';

      background-color: rgba($color: #000, $alpha: 0.15);

      inset: 0 0 0 0;
    }

    .container {
      z-index: 1;

      display: flex;
    }

    .links {
      justify-content: center;
      @include media-breakpoint-down(md) {
        flex-direction: row;
      }
    }

    .video-js {
      position: absolute;
      z-index: 0;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transform: translateY(-50%);

      object-fit: cover;

      .vjs-tech {
        object-fit: cover;
      }
    }
  }
}
