.video {
  .vjs {
    &-tech {
      background-color: #fff !important;
    }

    &-poster {
      background-color: #fff !important;
    }
  }
}
