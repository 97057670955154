.quotes {
  &.mirrored {
    .card {
      flex-direction: row-reverse;

      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
      }
    }
  }

  .slick-track {
    display: flex !important;

    align-items: center !important;
  }

  .card {
    flex-direction: row;

    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
    }

    &-image {
      display: flex;

      justify-content: center;
      flex-shrink: 0;
      align-items: center;
    }

    .preline {
      font-family: $font-family-base;
      font-size: rem(14px);
      font-weight: $font-weight-regular;
      line-height: 1;

      margin-bottom: 5px;
    }

    blockquote {
      .teaser {
        font-style: italic;
      }
    }
  }

  &-text {
    position: relative;

    max-width: 630px;
    margin-bottom: 0;
    padding: 15px;
  }

  &-icon {
    position: absolute;

    transform: translate(-100%, -100%);

    svg {
      color: $secondary;
    }
  }
}
