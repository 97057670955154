.menu-section {
  &.vertical-list {
    .card-body {
      display: flex;

      align-items: center;
      justify-content: center;
    }

    .nav {
      flex-direction: column;

      &-item {
        display: flex;

        align-items: center;
      }

      &-link {
        display: flex;

        &-icon {
          display: flex;
          
          margin-right: 10px;

          color: $primary;
        }
      }
    }
  }
}
