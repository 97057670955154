.menu-pages-with-search {
  &.secondary {
    width: 100%;

    @include media-breakpoint-down(xxl) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:nth-child(1) { // first level
      margin-top: -8px;
      padding-top: 8px;
      padding-bottom: 8px;

      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

      @include media-breakpoint-down(xxl) {
        margin-top: 0;
        padding-top: 4px;
        padding-bottom: 4px;

        box-shadow: none;
      }
    }

    &:nth-child(2) {
      margin-top: 1px;  // fix for box-shadow overlap // second level  // second level 
      margin-bottom: -8px;

      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

      justify-content: center;

      .nav {
        &-item {
          margin-right: 38px;
          padding-top: 14px;
          padding-bottom: 14px;

          @include media-breakpoint-down(xxl) {
            // margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        &-link {
          color: $brand-dark !important;
        }
      }
    }

    .navbar {
      &-nav {
        .nav {
          &-item {
            @include media-breakpoint-down(xxl) {
              border: none;
            }
          }

          &-link {
            @include media-breakpoint-down(xxl) {
              font-size: rem(18px);

              padding: 0.75rem 0;
            }
          }
        }
      }

      &-toplinks {
        width: auto;
        margin: 0;
        padding: 0;

        background-color: transparent;

        @include media-breakpoint-down(xxl) {
          width: 100%;
        }

        .toplinks {
          @extend .navbar-nav;
          @include media-breakpoint-down(xxl) {
            width: 100%;

            align-items: stretch;
          }

          &-link {
            @extend .nav-link;
          }
        }
      }

      &-search {
        &-wrapper {
          @include media-breakpoint-down(xxl) {
            display: flex;

            padding: 10px 30px 10px 0;

            align-self: stretch;
            align-items: center;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        &-form {
          width: 100%;
          max-width: 420px;
          padding: 6px 0;

          @include media-breakpoint-down(xxl) {
            max-width: 100%;
            padding: 12px 0;
          }

          .search {
            width: 100%;

            .form-label {
              left: 24px;
            }

            &-input {
              padding-right: 1rem;
              padding-left: 40px;

              background-color: $brand-light;
            }

            &-button {
              right: auto;
              left: 0;

              margin-right: 15px;
              margin-left: 0;
            }
          }
        }
      }

      &-collapse {
        flex-shrink: 0;
        @include media-breakpoint-down(xxl) {
          flex-shrink: 1;
        }

        &-inner {
          max-width: 1140px;

          @include media-breakpoint-down(xl) {
            max-width: 960px;
          }
          @include media-breakpoint-down(lg) {
            max-width: 720px;
          }
          @include media-breakpoint-down(md) {
            max-width: 540px;
          }
        }
      }

      &-brand {
        flex-shrink: 0;
        @include media-breakpoint-down(xxl) {
          display: flex;

          flex-grow: 1;
          justify-content: center;
        }
      }
    }

    .dropdown {
      &-toggle {
        &::before,
        &::after {
          @include media-breakpoint-down(xxl) {
            height: 3px;

            background-color: #d1d1d1;
          }
        }
      }

      &-menu {
        margin-top: -1px;
        padding: 0;

        border-radius: 0;

        @include media-breakpoint-down(xxl) {
          margin-top: 0;
        }

        li {
          &:first-child {
            @include media-breakpoint-down(xxl) {
              .dropdown-item {
                padding-top: 0.5rem;
              }
            }
          }

          &:last-child {
            @include media-breakpoint-down(xxl) {
              padding-bottom: 0;

              .dropdown-item {
                padding-bottom: 0.5rem;

                border-bottom: none;
              }
            }
          }
        }
      }

      &-item {
        padding: 1.25rem;

        transition: background-color 0.15s ease-in-out;

        border-bottom: 1px solid #e0e0e0;

        @include media-breakpoint-down(xxl) {
          font-size: rem(18px);

          padding: 0.75rem 0.75rem 0.75rem 2.5rem;

          border-bottom: none;
        }

        &:hover,
        &:focus,
        &:active {
          background-color: $brand-light;

          .dropdown-text {
            color: $brand-dark !important;
          }
        }
      }
    }
  }
}
