.display-1 {
  font-weight: $font-weight-black;
  line-height: $line-height-display-desktop-1;

  text-transform: uppercase;

  color: $brand-tertiary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-1;
    line-height: $line-height-display-mobile-1;
  }
}

.display-2 {
  font-weight: $font-weight-black;
  line-height: $line-height-display-desktop-2;

  text-transform: uppercase;

  color: $brand-tertiary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-2;
    line-height: $line-height-display-mobile-2;
  }
}

.display-3 {
  font-weight: 900;
  line-height: $line-height-display-desktop-3;

  text-transform: uppercase;

  color: $brand-secondary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-3;
    line-height: $line-height-display-mobile-3;
  }
}

.display-4 {
  font-weight: $font-weight-black;
  line-height: $line-height-display-desktop-4;

  text-transform: uppercase;

  color: $brand-primary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-4;
    line-height: $line-height-display-mobile-4;
  }
}

.display-5 {
  // text-transform: uppercase;
  font-size: $font-size-display-desktop-4;
  font-weight: $font-weight-black;
  line-height: $line-height-display-desktop-4;

  text-transform: none !important;

  color: #6a6a6a;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-4;
    line-height: $line-height-display-mobile-4;
  }
}

.display-6 {
  font-size: $font-size-heading-desktop-3;
  font-weight: $font-weight-black;
  // line-height: $line-height-display-desktop-6;
  line-height: $line-height-heading-desktop-3;

  text-transform: none !important;

  color: #6a6a6a;
  // @include media-breakpoint-down(lg) {
  //   font-size: $font-size-display-mobile-6;
  //   line-height: $line-height-display-mobile-6;
  // }

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-3;
    line-height: $line-height-heading-mobile-3;
  }
}
