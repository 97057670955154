.stage {
  &.equal {
    text-align: left;

    @include media-breakpoint-down(lg) {
      min-height: auto !important;
    }
    // @include media-breakpoint-down(xl) {
    //   h2.headline {
    //     font-size: 3rem;
    //   }
    // }

    .preline,
    .headline,
    .teaser,
    .links {
      padding-right: 10%;
      // padding-left: 5%;
      padding-left: 0;

      @include media-breakpoint-down(lg) {
        padding-right: 0;
        padding-left: 0;

        text-align: initial;
      }
    }

    .mirrored {
      // text-align: right;

      .preline,
      .headline,
      .teaser,
      .links {
        // padding-right: 5%;
        padding-right: 0;
        padding-left: 10%;

        @include media-breakpoint-down(lg) {
          padding-right: 0;
          padding-left: 0;

          text-align: initial;
        }
      }
    }

    .stage-text {
      display: flex;
      flex-direction: column;

      height: 100%;

      justify-content: center;
      @include media-breakpoint-down(lg) {
        // min-height: 350px;
        min-height: 50vh;
        padding-bottom: 30px;
      }
    }
  }
}
