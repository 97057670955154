.buy-fonds-modal {
  .modal {
    &-dialog {
      width: 95%;
      max-width: 1600px;
    }

    &-content {
      padding: 20px;

      background-color: $brand-light;
    }

    &-close {
      display: flex;

      margin-bottom: 20px;

      justify-content: flex-end;
    }
  }

  .btn-close {
    display: flex;

    width: auto;
    height: auto;

    background: none;

    align-items: center;

    svg {
      margin-left: 10px;

      color: $black;
    }
  }

  .headline {
    font-size: rem(40px);
  }

  .teaser {
    font-size: rem(20px);
  }

  &-inner {
    width: 100%;
    max-width: 1135px;
    margin: 0 auto;
  }

  &-list {
    display: grid;

    margin-bottom: 50px;

    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    &-item {
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1200px) {
        flex-direction: column;

        align-items: center;
        justify-content: center;
      }

      &-logo {
        width: 100%;
        max-width: 300px;

        @media (max-width: 1200px) {
          padding: 20px 20px 0;
        }
      }

      &-links {
        display: flex;
        flex-direction: column;

        padding: 20px;

        flex-grow: 1;
        align-items: center;

        @media (max-width: 1200px) {
          flex-grow: initial;
        }

        small {
          margin-top: rem(16px);
        }
      }
    }
  }
}
