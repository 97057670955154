// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'MuseoSans', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace) !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: $font-size-text-md; // 18px
$font-size-sm: $font-size-text-sm;   // 14px
$font-size-lg: $font-size-text-lg;   // 28px

$font-weight-lighter: lighter;
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-light;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-heading-desktop-1;  // 40px
$h2-font-size: $font-size-heading-desktop-2;  // 32px
$h3-font-size: $font-size-heading-desktop-3;  // 28px
$h4-font-size: $font-size-heading-desktop-4;  // 24px
$h5-font-size: $font-size-heading-desktop-5;  // 20px
$h6-font-size: $font-size-heading-desktop-6;  // 16px

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: rem(12px)
);
// scss-docs-end font-sizes

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: $font-weight-light;
$headings-line-height: 1.2 !default;
$headings-color: $brand-primary;

// scss-docs-start display-headings
$display-font-sizes: (
  1: $font-size-display-desktop-1,
  2: $font-size-display-desktop-2,
  3: $font-size-display-desktop-3,
  4: $font-size-display-desktop-4,
  5: $font-size-display-desktop-5,
  6: $font-size-display-desktop-6
);

$display-font-weight: $font-weight-light;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

$lead-font-size: $font-size-text-lg;   // 22px;
$lead-font-weight: 300 !default;

$small-font-size: $font-size-text-sm;  // 14px

$sub-sup-font-size: 0.75em !default;

$text-muted: $gray-600 !default;

$initialism-font-size: $small-font-size !default;

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color: #565656;
$hr-height: $border-width !default;
$hr-opacity: 1;

$legend-margin-bottom: 1.5rem;
$legend-font-size: rem(20px);
$legend-font-weight: null !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;
