.features {
  &.slider {
    .slick-list {
      margin-top: rem(-16px);    // works in conjunction with box-shadow fix below
      margin-bottom: rem(-16px); // works in conjunction with box-shadow fix below
    }

    .slick-track {
      display: flex !important;
      overflow: hidden;

      padding: rem(16px) 0;      // box-shadow fix

      align-items: stretch !important;
      justify-content: stretch !important;
      @include media-breakpoint-down(md) {
        display: block !important;
      }
    }

    &.grayscale {
      .carousel-item {
        img {
          transition: filter 0.15s ease-in-out;

          filter: grayscale(1);
        }

        &:hover,
        &:focus,
        &:active {
          img {
            filter: grayscale(0);
          }
        }
      }
    }
  }
}
