.accordion {
  &.standard {
    .accordion,
    .accordion-flush {
      &-button {
        text-transform: uppercase;

        color: #6a6a6a;
      }
    }
  }
}
