.pagination {
  flex-wrap: wrap;

  .page-item {
    &.disabled {
      .page-link {
        color: transparentize($primary, 0.5);
      }
    }
  }

  .page-link {
    font-size: $font-size-pagination-md;
    font-weight: $font-weight-semi-bold;

    position: relative;

    display: flex;

    width: 48px;
    height: 48px;
    margin: 0 4px !important;

    border: none;
    border-radius: 50%;

    align-items: center;
    justify-content: center;

    &::after {
      position: absolute;
      bottom: 8px;

      width: 21px;
      height: 2px;

      content: '';
      transition: opacity 0.15s ease-in-out;

      opacity: 0;
      background-color: $primary;
    }

    &:hover,
    &:focus,
    &:active {
      &::after {
        opacity: 1;
      }
    }

    &-next,
    &-prev,
    &-first,
    &-last {
      color: $primary;

      &:hover,
      &:focus,
      &:active {
        &::after {
          opacity: 0;
        }
      }
    }
  }

  &-sm {
    .page-link {
      font-size: $font-size-pagination-sm;

      width: 40px;
      height: 40px;
      margin: 0 3px !important;
    }
  }

  &-lg {
    .page-link {
      font-size: $font-size-pagination-lg;

      width: 56px;
      height: 56px;
      margin: 0 5px !important;
    }
  }
}
