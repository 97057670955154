.personio {
  &-list {
    &-jobs {
      margin: 0;
      padding: 0;

      list-style-type: none;

      &-item {
        padding: 36px 0;

        border-bottom: 1px solid #ced4da !important;

        img {
          // max-width: 180px;
          max-width: 100px;
          margin-bottom: 24px;

          @include media-breakpoint-up(md) {
            // max-width: 120px;
            max-width: 100px;
            margin-right: 48px;
            margin-bottom: 0;
          }
        }

        a {
          font-size: rem(16px);
          font-weight: 500;

          display: flex;

          align-items: center;
          justify-content: space-between;
          @include media-breakpoint-down(md) {
            display: block;
          }

          &:hover,
          &:focus,
          &:active {
            span {
              color: inherit;
            }
          }

          span {
            font-size: rem(20px);
            font-weight: 900;

            margin-right: 15px;

            color: #333;

            flex-grow: 1;
            @include media-breakpoint-down(md) {
              display: block;

              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  &-details {
    &-job {
      &-description {
        padding: 40px;

        &-item {
          margin-bottom: 20px;

          @include media-breakpoint-down(md) {
            margin-bottom: 40px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-title {
          font-weight: 700;
        }
      }

      &-application {
        // padding: 40px;
        // padding-top: 0;
        // padding-bottom: 0;
        padding: 0;
      }
    }
  }
}


.personio-select-wrapper {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.personio-select {
  max-width: 300px;
  margin-top: 15px;
  margin-right: 25px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}
