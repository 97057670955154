.h1 {
  line-height: $line-height-heading-desktop-1;

  text-transform: uppercase;
  hyphens: initial;     // excluding from global styling to use &shy;

  color: $brand-primary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-1;
    line-height: $line-height-heading-mobile-1;
  }
}

.h2 {
  font-weight: $font-weight-black;
  line-height: $line-height-heading-desktop-2;

  text-transform: uppercase;
  hyphens: initial;     // excluding from global styling to use &shy;

  color: $black;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-2;
    line-height: $line-height-heading-mobile-2;
  }
}

.h3 {
  font-weight: $font-weight-black;
  line-height: $line-height-heading-desktop-3;

  text-transform: uppercase;
  hyphens: initial;     // excluding from global styling to use &shy;

  color: $brand-secondary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-3;
    line-height: $line-height-heading-mobile-3;
  }
}

.h4 {
  line-height: $line-height-heading-desktop-4;

  hyphens: initial;     // excluding from global styling to use &shy;

  color: $brand-primary;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-4;
    line-height: $line-height-heading-mobile-4;
  }
}

.h5 {
  font-weight: $font-weight-black;
  line-height: $line-height-heading-desktop-5;

  text-transform: uppercase;
  hyphens: initial;     // excluding from global styling to use &shy;

  color: #6a6a6a;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-5;
    line-height: $line-height-heading-mobile-5;
  }
}

.h6 {
  font-weight: $font-weight-bold;
  line-height: $line-height-heading-desktop-6;

  hyphens: initial;     // excluding from global styling to use &shy;

  color: #6a6a6a;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-6;
    line-height: $line-height-heading-mobile-6;
  }
}
