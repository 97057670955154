// paddings
.pt {
  &-xxxsmall {
    padding-top: $size-xxxs;
  }
  &-xxsmall {
    padding-top: $size-xxs;
  }
  &-xsmall {
    padding-top: $size-xs;
  }
  &-small {
    padding-top: $size-sm;
  }
  &-medium {
    padding-top: $size-md;
  }
  &-large {
    padding-top: $size-lg;
  }
  &-xlarge {
    padding-top: $size-xl;

    @include media-breakpoint-down(md) {
      padding-top: $size-lg;
    }
  }
  &-xxlarge {
    padding-top: $size-xxl;

    @include media-breakpoint-down(md) {
      padding-top: $size-lg;
    }
  }
  &-xxxlarge {
    padding-top: $size-xxxl;

    @include media-breakpoint-down(md) {
      padding-top: $size-lg;
    }
  }
}

.pb {
  &-xxxsmall {
    padding-bottom: $size-xxxs;
  }
  &-xxsmall {
    padding-bottom: $size-xxs;
  }
  &-xsmall {
    padding-bottom: $size-xs;
  }
  &-small {
    padding-bottom: $size-sm;
  }
  &-medium {
    padding-bottom: $size-md;
  }
  &-large {
    padding-bottom: $size-lg;
  }
  &-xlarge {
    padding-bottom: $size-xl;

    @include media-breakpoint-down(md) {
      padding-bottom: $size-lg;
    }
  }
  &-xxlarge {
    padding-bottom: $size-xxl;

    @include media-breakpoint-down(md) {
      padding-bottom: $size-lg;
    }
  }
  &-xxxlarge {
    padding-bottom: $size-xxxl;

    @include media-breakpoint-down(md) {
      padding-bottom: $size-lg;
    }
  }
}

// margins
.mb {
  &-xxxsmall {
    margin-bottom: $size-xxxs;
  }
  &-xxsmall {
    margin-bottom: $size-xxs;
  }
  &-xsmall {
    margin-bottom: $size-xs;
  }
  &-small {
    margin-bottom: $size-sm;
  }
  &-medium {
    margin-bottom: $size-md;
  }
  &-large {
    margin-bottom: $size-lg;
  }
  &-xlarge {
    margin-bottom: $size-xl;

    @include media-breakpoint-down(md) {
      margin-bottom: $size-lg;
    }
  }
  &-xxlarge {
    margin-bottom: $size-xxl;

    @include media-breakpoint-down(md) {
      margin-bottom: $size-lg;
    }
  }
  &-xxxlarge {
    margin-bottom: $size-xxxl;

    @include media-breakpoint-down(md) {
      margin-bottom: $size-lg;
    }
  }
}

.mt {
  &-xxxsmall {
    margin-top: $size-xxxs;
  }
  &-xxsmall {
    margin-top: $size-xxs;
  }
  &-xsmall {
    margin-top: $size-xs;
  }
  &-small {
    margin-top: $size-sm;
  }
  &-medium {
    margin-top: $size-md;
  }
  &-large {
    margin-top: $size-lg;
  }
  &-xlarge {
    margin-top: $size-xl;

    @include media-breakpoint-down(md) {
      margin-top: $size-lg;
    }
  }
  &-xxlarge {
    margin-top: $size-xxl;

    @include media-breakpoint-down(md) {
      margin-top: $size-lg;
    }
  }
  &-xxxlarge {
    margin-top: $size-xxxl;

    @include media-breakpoint-down(md) {
      margin-top: $size-lg;
    }
  }
}
