.nav {
  &-link {
    position: relative;

    // text-transform: uppercase;

    &.active {
      color: shade-color($body-color, 10%);
    }

    &:hover,
    &:focus,
    &:active {
      &::before {
        position: absolute;
        right: 12px;
        bottom: 0;
        left: 12px;

        display: block;

        height: 2px;

        content: '';

        background-color: $primary;
      }
    }
  }

  &.categories {
    // override .navbar styles, that was used as hack because of Popper issues
    flex-direction: row;

    min-height: auto;
    padding: 0;

    border: none;
    background-color: transparent !important;
    box-shadow: none;

    justify-content: start;
  }
}
