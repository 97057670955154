.login-modal {
  .modal {
    &-dialog {
      max-width: 600px;
    }

    &-header {
      flex-direction: column;

      padding-bottom: $modal-inner-padding / 2;
    }

    &-footer {
      padding-top: $modal-inner-padding / 2;
    }
  }

  &-input {
    margin-bottom: 20px;
  }

  &-submit {
    padding-top: 10px;

    text-align: right;
  }

  &-description {
    display: flex;

    min-height: 250px;
    margin-top: 32px;
    margin-bottom: 12px;
    padding: 16px;

    color: unset;
    background-size: cover;

    justify-content: space-between;

    &-image {
      max-width: 100px;
      margin-left: 16px;

      align-self: flex-start;
    }

    &-content {
      display: flex;
      flex-direction: column;

      justify-content: space-between;
    }
  }
}
