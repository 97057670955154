// .stage {
//   &.promotion {
//     display: flex;

//     align-items: center;

//     .row {
//       position: relative;
//       z-index: 1;
//     }

//     .headline {
//       text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
//     }

//     .teaser {
//       font-size: rem(36px);

//       text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
//     }

//     .promotion {
//       &-container {
//         position: relative;

//         @include media-breakpoint-up(md) {
//           max-width: 80%;
//           margin: 0 auto;
//         }
//       }

//       &-info {
//         display: flex;

//         width: 195px;
//         height: 195px;
//         margin: 0 auto 40px;
//         padding: 25px;

//         border-radius: 50%;
//         background-color: $primary;

//         align-items: center;
//         justify-content: center;
//         @include media-breakpoint-up(md) {
//           position: absolute;
//           top: -105px;
//           left: -200px;
//         }

//         .preline {
//           font-size: rem(30px);
//           font-weight: $font-weight-semi-bold;
//           font-style: italic;

//           margin: 0;

//           transform: rotate(-10deg);
//         }
//       }
//     }
//   }
// }


.stage {
  &.promotion {
    text-align: left;

    justify-content: center;
    // @include media-breakpoint-down(md) {
    //   min-height: auto !important;
    // }

    .headline,
    .stage-logo {
      padding-right: 20%;
      // padding-left: 10%;
      padding-left: 0;

      color: $body-color;

      @include media-breakpoint-down(md) {
        padding-right: 0;
        padding-left: 0;

        text-align: initial;
      }
    }

    .mirrored {
      text-align: right;

      .headline,
      .stage-logo {
        // padding-right: 10%;
        padding-right: 0;
        padding-left: 20%;

        @include media-breakpoint-down(md) {
          padding-right: 0;
          padding-left: 0;

          text-align: initial;
        }
      }
    }

    .stage-logo {
      display: flex;

      height: 130px;
      margin-top: 80px;

      flex-grow: 1;
      align-items: center;
      @include media-breakpoint-down(md) {
        margin-top: 0;
        margin-bottom: 30px;
      }

      + .stage-text {
        height: auto;
        padding-bottom: 80px;

        @include media-breakpoint-down(md) {
          min-height: auto;
          padding-bottom: 30px;
        }
      }
    }

    .stage-text {
      display: flex;
      flex-direction: column;

      height: 100%;

      justify-content: center;
      @include media-breakpoint-down(md) {
        min-height: 350px;
        padding-bottom: 30px;
      }
    }
  }
}
