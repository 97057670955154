.menu-pages-with-search {
  &.standard {
    .navbar {
      &-toplinks {
        width: auto;
        margin-right: -1 * $container-padding-x;
        margin-bottom: -2rem; // navbar-inner padding-bottom
        margin-left: -1 * $container-padding-x;

        flex-grow: 1;
      }
    }
  }
}
