.text-and-icon-list {
  &.secondary {
    // background-color: $tertiary;
    box-shadow: none;

    .col-lg-6 {
      .headline {
        font-weight: 300;

        color: $body-color;

        a {
          font-weight: 300;

          color: inherit !important;
        }
      }
    }
  }
}
