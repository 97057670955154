.grid-gallery {
  &.wall {
    .card {
      position: relative;

      &::before {
        display: block;

        padding-top: 100%;

        content: '';
      }

      &:hover,
      &:focus,
      &:active {
        .card-background {
          &::before {
            opacity: 0.5;
          }

          img {
            transform: scale(1.2);
          }
        }
      }

      &-background,
      &-image,
      &-info {
        position: absolute;
        z-index: 1;
      }

      &-background {
        inset: 0 0 0 0;

        &::before {
          position: absolute;
          z-index: 1;

          content: '';
          transition: opacity 0.15s ease-in-out;

          opacity: 0.35;
          background-color: #000;

          inset: 0 0 0 0;
        }

        img {
          width: 100%;
          height: 100%;

          transition: transform 0.3s ease-in-out;

          object-fit: cover;
        }
      }

      &-image {
        z-index: 1;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }


      &-info {
        z-index: 1;
        right: 15px;
        // bottom: 64px;
        bottom: 10%;
        left: 15px;

        text-align: center;

        .headline,
        .teaser {
          color: $white;
        }

        .headline {
          font-weight: 300;

          @include media-breakpoint-down(xl) {
            font-size: rem(20px);
          }
        }
      }
    }
  }
}
