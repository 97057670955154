.only-image {
  &.full-width {
    margin-right: -$container-padding-x;
    margin-left: -$container-padding-x;

    @include media-breakpoint-down(md) {
      height: auto !important;

      img {
        position: relative !important;
      }
    }
  }
}
