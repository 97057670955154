.gallery {
  &.centered-cards-slider {
    .slick-list {
      margin-top: rem(-16px);    // works in conjunction with box-shadow fix below
      margin-bottom: rem(-16px); // works in conjunction with box-shadow fix below
    }

    .slick-track {
      display: flex !important;
      overflow: hidden;
      // padding: rem(16px) 0;      // box-shadow fix

      padding: rem(48px) 0; // box-shadow fix + centered mode fix

      align-items: center !important;
      justify-content: stretch !important;
    }

    .slick-prev {
      z-index: 1;
      left: 100px;

      @include media-breakpoint-down(md) {
        left: 50px;
      }
    }

    .slick-next {
      z-index: 1;
      right: 100px;

      @include media-breakpoint-down(md) {
        right: 50px;
      }
    }

    .slick-slide {
      transition: all 0.15s ease-in-out;

      &[aria-hidden='true'] {
        opacity: 0.5;
      }

      &.slick-center {
        z-index: 1;

        transform: scale(1.25);
      }

      .card {
        width: 100%;

        &-body {
          padding: $card-spacer-y/1.5 $card-spacer-x/1.5;
        }
      }
    }
  }
}
