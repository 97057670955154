.stage {
  &.full-width {
    display: flex;

    align-items: center;

    .preline,
    .headline,
    .teaser {
      position: relative;
    }

    .container {
      .row {
        &::before {
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          display: block;

          content: '';

          opacity: 0.35;
          background-image: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0) 100%);

          @include media-breakpoint-down(md) {
            background-color: black;
            background-image: none;
          }
        }

        &.flex-row-reverse {
          &::before {
            background-image: linear-gradient(to left, #000 0%, rgba(0, 0, 0, 0) 100%);

            @include media-breakpoint-down(md) {
              background-color: black;
              background-image: none;
            }
          }
        }
      }
    }
  }
}
