.footer {
  hr {
    background-color: #565656 !important;
  }

  .footerlinks {
    .link {
      font-size: rem(12px);
      font-weight: 300;

      margin-right: 24px;

      text-transform: none;

      &:last-child {
        margin-right: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  .block-items {
    .teaser {
      font-size: rem(14px);
    }

    .link {
      font-size: rem(14px);
      font-weight: 300;

      text-transform: none;
    }
  }
}
