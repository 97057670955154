.stage {
  .scroll-to-next {
    position: absolute;
    z-index: 5;
    bottom: 1px;
    left: 50%;

    display: flex;

    width: 80px;
    height: 80px;

    transform: translateX(-50%);

    border: none;
    background-color: transparent;

    appearance: none;
    justify-content: center;
    align-items: center;

    svg {
      position: relative;
      z-index: 3;

      height: 40px;

      animation: scrollDown 3s infinite;

      path {
        fill: #fff;
      }
    }

    &::before {
      position: absolute;
      z-index: 1;

      display: block;

      width: 100%;
      height: 100%;

      content: '';

      opacity: 0.6;
      background-color: #c52929;
      // background-image: linear-gradient(0deg, rgba(197, 41, 41, 0.6), rgba(197, 41, 41, 0.6)), linear-gradient(0deg, rgba(197, 41, 41, 0.6), rgba(197, 41, 41, 0.3));

      background-blend-mode: multiply;
    }

    // &::after {
    //   position: absolute;
    //   z-index: 2;

    //   display: block;

    //   width: 100%;
    //   height: 100%;

    //   content: '';

    //   opacity: 0.3;
    //   background-color: #c52929;

    //   background-blend-mode: normal;
    // }
  }

  &-img {
    @include media-breakpoint-down(lg) {
      position: relative !important;

      width: 100vw !important;
      // width: auto !important;
      max-width: 100vw;
      height: 50vh !important;
      margin-right: -50vw;
      margin-left: -50vw;
    }
  }
}


@keyframes scrollDown {
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(-3px);
  }
}
