.menu-section {
  .links {
    margin-right: 0;
    margin-left: 0;

    > * {
      margin-right: 0;
      margin-left: 0;
    }
  }
}
