// Pagination

$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-padding-y-sm: 0;
$pagination-padding-x-sm: 0;
$pagination-padding-y-lg: 0;
$pagination-padding-x-lg: 0;

$pagination-color: transparentize($body-color, 0.5);
$pagination-bg: transparent;
$pagination-border-width: $border-width !default;
$pagination-border-radius: 50%;
$pagination-margin-start: -$pagination-border-width !default;
$pagination-border-color: $gray-300 !default;

$pagination-focus-color: $body-color;
$pagination-focus-bg: transparent;
$pagination-focus-box-shadow: none;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $body-color;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;

$pagination-active-color: $body-color;
$pagination-active-bg: transparent;
$pagination-active-border-color: transparent;

$pagination-disabled-color: rgba(80,80,80,0.5);
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$pagination-border-radius-sm: 50%;
$pagination-border-radius-lg: 50%;
