.text-and-bullets {
  &.standard {
    .list {
      li {
        &::before {
          font-size: 28px;
          line-height: 1;

          content: '\2022';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        }
      }
    }
  }
}
