.quotes {
  &.secondary {
    .card {
      border: none;
      background-color: transparent;
      box-shadow: none;

      align-items: center;

      &-body {
        @include media-breakpoint-down(lg) {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .quotes {
        &-text {
          max-width: 530px;

          hyphens: none;
        }
      }

      .teaser {
        // font-size: rem(40px);
        font-size: 2.3rem;
        font-weight: $font-weight-black;
        line-height: normal;

        position: relative;

        letter-spacing: normal;

        color: $primary;

        @include media-breakpoint-down(xxl) {
          font-size: 1.8rem;
          line-height: normal;
        }
      }

      .preline {
        font-size: rem(24px);
        font-weight: 300;

        margin-top: 30px;

        text-transform: none;

        color: $body-color;

        @include media-breakpoint-down(xxl) {
          font-size: rem(18px);
        }

        &::after {
          display: none;
        }
      }

      .headline {
        font-size: rem(14px);
        font-weight: 300;

        margin-top: 10px;

        text-transform: none;

        color: transparentize($body-color, 0.5);
      }
    }
  }
}
