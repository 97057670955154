.debug {
  .alert {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;

    color: black;
    background-color: red;
    span {
      color: white;
    }
  }
}
