.features {
  .card {
    background-color: $primary;
    &-link {
      text-decoration: unset;

      color: unset;
    }

    a {
      color: $white !important;

      &:hover,
      &:focus,
      &:active {
        color: shade-color($white, 0.2);
      }
    }

    .preline {
      color: $white !important;
    }

    .headline {
      // font-weight: 400;
      color: $white !important;
    }

    .teaser {
      color: $white !important;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6,
      .display-1,
      .display-2,
      .display-3,
      .display-4,
      .display-5,
      .display-6 {
        color: $white !important;
      }
    }

    ul,
    ol {
      li {
        &::before {
          color: $white;
        }
      }
    }
  }
}
