.timeline {
  &.timeline-slider {
    .timeline {
      &-card {
        &::before {
          display: none;
        }
      }

      &-line {
        position: relative;
        z-index: 1;
        left: 0;

        display: flex;
        overflow-x: auto;

        width: auto;
        margin-bottom: 0;
        padding-right: 15px;
        padding-bottom: 20px;
        padding-left: 15px;

        transform: none;

        background-color: transparent;

        flex-wrap: nowrap;

        &-wrapper {
          display: flex;

          justify-content: center;
        }

        &-item {
          position: relative;

          min-width: 100px;

          list-style-type: none;

          transition: opacity 0.15s ease-in-out;

          opacity: 0.5;

          &:hover,
          &:focus,
          &:active {
            opacity: 1;
          }

          &.highlighted {
            opacity: 1;
          }

          &.active {
            color: $primary;

            &::before {
              background-color: $primary;
            }

            .timeline-line-marker {
              &::before {
                background-color: $primary;
              }

              &::after {
                opacity: 1;
              }
            }
          }

          &:last-child {
            &::before {
              display: none;
            }
          }

          &::before {
            position: absolute;
            right: 0;
            bottom: 8px;
            left: 0;

            display: block;

            height: 1px;

            content: '';

            background-color: $body-color;
          }
        }

        &-trigger {
          position: relative;

          padding: 16px 10px;

          color: inherit;
          border: none;
          border: none;
          background-color: transparent;

          appearance: none;
        }

        &-marker {
          &::before,
          &::after {
            position: absolute;
            right: 0;
            bottom: 8px;
            left: 0;

            display: block;

            content: '';
            transform: translateY(50%);
            pointer-events: none;

            border-radius: 50%;
          }

          &::before {
            width: 12px;
            height: 12px;

            transition: background-color 0.15s ease-in-out;

            background-color: $body-color;
          }

          &::after {
            left: -3px; // 6px / 2

            width: 18px;  // ::before + 6px
            height: 18px; // ::before + 6px

            transition: opacity 0.15s ease-in-out;

            opacity: 0;
            border: 2px solid $primary;
            background-color: transparent;
          }
        }

        &-date {
          display: inline-block;

          transform: translateX(-50%);
        }
      }
    }
  }
}
