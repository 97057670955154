.stage {
  &.slideshow {
    display: flex;

    @include media-breakpoint-down(xl) {
      h1.headline {
        font-size: 3rem;
      }
    }

    &::after {
      position: absolute;

      display: block;

      content: '';

      background-color: rgba($color: #000, $alpha: 0.15);

      inset: 0 0 0 0;
    }

    .container {
      z-index: 1;

      display: flex;
    }

    .links {
      justify-content: center;
      @include media-breakpoint-down(md) {
        flex-direction: row;
      }
    }

    .carousel {
      position: absolute;
      z-index: 0;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transform: translateY(-50%);

      object-fit: cover;

      &-uninit {
        .carousel-item:first-child {
          z-index: 1;

          display: block;

          opacity: 1;
        }
      }

      .carousel-inner,
      .carousel-item {
        height: 100%;
      }

      .carousel-item img {
        width: 100%;
        height: 100%;
        // transition: transform 5000ms linear 0s;
        // transform: scale(1, 1);

        object-fit: cover;
        object-position: center;
      }

      .carousel-item-no-animation img {
        animation: none !important;
      }

      .carousel-item-video {
        .video-js {
          width: 100%;
          height: 100vh;

          video {
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .carousel-item.active img {
        animation: zoom 3500ms linear 0s;
        // transform: scale(1.05, 1.05);
      }
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.05, 1.05);
  }
}
